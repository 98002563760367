<template>
  <div :class="{'modal modal_wrap': modalView}">
    <div :class="{'modal_popup modal_content': modalView}">
      <div :class="{'modal__context': modalView}">
        <section class="item-types">

          <span class="modal__close" @click="backStep">
            <svg-icon name="arrow-left"/>
          </span>
          <h2 class="titlePage_subtitle titlePage">SELECT COLOUR</h2>

          <breadcrumbs v-if="!modalView"></breadcrumbs>

          <div class="row">
            <div class="col-12">
              <form action="" class="form" v-on:submit.prevent>
                <label class="form__label">
                  <input type="text" class="form__input"
                         placeholder="Type to Search"
                         name="search color"
                         v-model="color"
                  >
                </label>
                <button type="button" class="btn_link">
                  <svg-icon name="search"/>
                </button>
              </form>
            </div>
          </div>
        </section>

        <section class="item-types">
          <div class="row"
               v-for="(item, key) in colors"
          >
            <div class="col-12">
              <h2 class="titlePage_subtitle titlePage">{{key}}</h2>
            </div>
            <div class="col-lg-3 col-sm-4 col-6"
                 v-for="colour in item.colours"
                 :key="colour.id"
                 @click="selectColors(colour, item)"
            >
              <div class="box">
                <img class="box__img" :src="colour.image" alt="color.colour_name" v-if="colour.image">
                <p v-else-if="colour.hexcode">
                  <svg-icon name="palette" class="large_icon box__img" :style="{color: colour.hexcode}"/>
                </p>
                <p v-if="!colour.image && !colour.hexcode">
                  <svg-icon name="palette" class="large_icon box__img"/>
                </p>
                <p class="text_default">{{colour.colour_name}}</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

    <div class="modal modal_wrap" v-if="warning" tabindex="1">
      <div class="modal_popup">
        <p class="text text_white">Chosen colour requires additional price. Please, click "I Accept" button if
          you want to continue.</p>
        <div class="justify-content-between">
          <button class="btn btn_transparent" @click="warning = !warning"><svg-icon name="solid/step-backward" /> Back</button>
          <button class="btn btn_transparent" @click="accept"><svg-icon name="solid/check" /> I Accept</button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import breadcrumbs from "./../../parts/breadcrumbs";
  import {mapState, mapGetters, mapMutations} from 'vuex';
  import {searchColors} from '../../../helpers/search';

  export default {
    name: "AvailableColour",
    components: {breadcrumbs},
    data() {
      return {
        color: '',
        warning: false,
        colorItem: {},
        selectColor: '',
      }
    },

    props: ['parent', 'title'],

    methods: {
      ...mapMutations('options', ['setType']),
      selectColors(target, group) {
        if (group.is_show_price_warning) {
          this.warning = !this.warning;
          this.selectColor = target.id;
        }
        else {
          this.$store.commit('options/setColorToOptionsWithColor', target.id);
          this.$store.dispatch('options/updateAvailableOptionsPrice');
          this.$store.commit('options/closeAvailable');
        }
      },

      accept() {
        this.$store.commit('options/setColorToOptionsWithColor', this.selectColor);
        this.warning = false;
        this.$store.commit('options/closeAvailable');
        this.$store.dispatch('options/updateAvailableOptionsPrice');
      },

      backStep(){
        this.setType(this.$parent.prevOption);
      }
    },
    watch: {
      color(n) {
        this.$store.commit('colors/filtered', searchColors(n, this.allColors));
      }
    },

    computed: {
      ...mapState('options', ['name', 'price', 'modalView', 'insertColoursDefault', 'insertColours']),
      ...mapState('app', ['data']),

      ...mapGetters({
        allColors: 'colors/colors',
        colors: 'colors/filtrated',
      }),
    },
    created() {
      this.$store.dispatch('colors/getColors');
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../src/assets/scss/utils/vars";

  .box {
    margin-bottom: 1rem;
    &__img {
      position: static;
      display: block;
      margin: 0 auto;
      margin-bottom: 20px;
      width: 100%;
      max-width: 160px;
      height: 100px;
    }
    p {
      text-align: center;
    }
  }

  .form {
    position: relative;
    .btn_link {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      cursor: pointer;
      svg {
        color: $green;
      }
    }

    &__input {
      padding-right: 40px;
      border: 2px solid #0b3553;
    }

  }

  .justify-content-between {
    display: flex;
  }

  .modal__close {
    color: $green;
  }

</style>
